// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-assessment-tsx": () => import("./../../../src/pages/services/assessment.tsx" /* webpackChunkName: "component---src-pages-services-assessment-tsx" */),
  "component---src-pages-services-mental-health-services-tsx": () => import("./../../../src/pages/services/mental-health-services.tsx" /* webpackChunkName: "component---src-pages-services-mental-health-services-tsx" */),
  "component---src-pages-services-positive-behaviour-support-tsx": () => import("./../../../src/pages/services/positive-behaviour-support.tsx" /* webpackChunkName: "component---src-pages-services-positive-behaviour-support-tsx" */),
  "component---src-pages-services-supervision-tsx": () => import("./../../../src/pages/services/supervision.tsx" /* webpackChunkName: "component---src-pages-services-supervision-tsx" */),
  "component---src-pages-services-training-agency-specialist-services-tsx": () => import("./../../../src/pages/services/training-agency-specialist-services.tsx" /* webpackChunkName: "component---src-pages-services-training-agency-specialist-services-tsx" */)
}

